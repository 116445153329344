@use 'src/styles/variables';

.button {
  height: 48px;
  padding: 12px 20px;
}

.button:disabled {
  border: none;
}

@use 'src/styles/variables';

.section {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.description {
  color: variables.$N6;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.02em;
  margin-top: 12px;
}

.heading {
  font-size: 18px;
  line-height: 1.25;
}

.moreButton {
  display: block;
  font-family: inherit;
  color: variables.$primary;
  font-weight: 500;
  font-size: 16px;
  border: none;
  background-color: transparent;
  line-height: 1.5;
  padding: 12px 30px;
  margin-right: 0;
  margin-left: auto;
  margin-top: 20px;
}

.infoWrap {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}

.info {
  display: flex;
  align-items: center;
  gap: 4px;
}

.info:last-child {
  margin-top: 8px;
}

.avatar {
  margin-top: 4px;
}

.text {
  font-weight: 500;
  font-size: 12px;
  color: variables.$N6;
}

.name {
  font-family: variables.$font-heading;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.25;
  margin-bottom: 14px;
  color: variables.$N6;
}

@media (min-width: variables.$media-sm) {
  .description {
    font-size: 18px;
    line-height: 1.25;
  }

  .name {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 23px;
  }

  .avatar {
    margin-top: 8px;
  }

  .info:last-child {
    margin-top: 10px;
  }

  .heading {
    font-size: 26px;
    letter-spacing: -0.02em;
  }
}

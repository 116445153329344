$primary: #003342;
$primary-hover: '#074A5E';
$secondary: #c0d32c;
$secondary-hover: #b3c332;
$secondary-light: #fcffe8;

$success: #75aa12;
$error: #ea2a2a;

$N1: #ffffff;
$N2: #f5f5f5;
$N3: #eaeaea;
$N4: #b8b2b2;
$N5: #4f4f4f;
$N6: #1d1617;

$font-heading: 'Lato', sans-serif;
$font-body: 'Montserrat', sans-serif;

$media-xs: 375px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;
$media-xxl: 1400px;

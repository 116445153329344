@use 'src/styles/variables';

.categoriesSection {
  margin-bottom: 40px;
  margin-left: 0;
  margin-right: 0;
}

.categoriesList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

@media (min-width: variables.$media-md) {
  .categoriesSection {
    margin-left: 26px;
    margin-right: 26px;
    margin-bottom: 80px;
  }
}

@media (min-width: variables.$media-lg) {
  .categoriesList {
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  }

  .categoriesSection {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 140px;
  }
}

@use 'src/styles/variables';

.popularGoodsSection {
  display: grid;
  gap: 24px;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 40px;
}

@media (min-width: variables.$media-sm) {
  .popularGoodsSection {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: variables.$media-md) {
  .popularGoodsSection {
    grid-template-columns: repeat(2, minmax(180px, 1fr));
    margin-left: 26px;
    margin-right: 26px;
    margin-bottom: 80px;
  }
}

@media (min-width: variables.$media-lg) {
  .popularGoodsSection {
    grid-template-columns: repeat(4, 1fr);
  }

  .popularGoodsSection {
    margin-bottom: 140px;
  }
}

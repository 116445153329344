@use 'src/styles/variables';

.label {
  :global {
    .ant-form-item-required {
      font-size: 18px !important;
      font-family: variables.$font-heading !important;
      font-weight: 600;
      margin-bottom: 20px !important;
    }
  }
}

.textArea {
  font-size: 12px;
}

@media (min-width: variables.$media-sm) {
  .textArea {
    font-size: 18px;
  }
}

@media (min-width: variables.$media-md) {
  .label {
    :global {
      .ant-form-item-required {
        font-size: 26px !important;
      }
    }
  }
}

@use 'src/styles/variables';

.section {
  padding: 0;
  margin-top: 40px;
  margin-bottom: 80px;
  box-shadow: none;
  border-radius: 0;
}

.header {
  margin: 0;
  padding-bottom: 32px;
  font-family: variables.$font-heading;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  color: variables.$primary;
  text-align: center;
}

@media (min-width: variables.$media-sm) {
  .section {
    background-color: variables.$N1;
    margin-top: 140px;
    margin-bottom: 140px;
    padding: 80px 100px;
    border-radius: 12px;
    box-shadow: 0 0 6px rgba(variables.$primary, 0.2);
  }

  .header {
    font-size: 28px;
    line-height: 125%;
    font-family: variables.$font-heading;
    font-weight: 500;
    margin-bottom: 40px;
  }
}

@media (min-width: variables.$media-md) {
  .header {
    font-size: 48px;
  }
}

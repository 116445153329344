@use 'src/styles/variables';

.productCard {
  border: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
  box-shadow: none !important;
}

.productCard {
  :global {
    .ant-card-cover {
      width: 100%;
    }

    .ant-card-body {
      padding: 0;
      width: 100%;
    }
  }
}

.productImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.cardDescription {
  margin-top: 20px;
}

.cardBottom {
  display: flex;
  justify-content: space-between;
  align-items: right;
  width: 100%;
}

.price {
  font-family: variables.$font-body;
  font-weight: 700;
  color: variables.$primary;
  font-style: normal;
  font-size: 24px;
}

.cardName {
  font-size: 18px;
}

.cardInfo {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 16px;
}

.rating {
  display: flex;
  align-items: center;
}

.rating span {
  font-size: 18px;
}

.starIcon {
  font-size: 12px;
  color: variables.$primary;
  margin-right: 5px;
  font-size: 14px;
}

.ratingValue {
  font-size: 12px;
  color: variables.$primary;
  font-weight: 700;
  font-size: 14px;
}

@media (min-width: variables.$media-sm) {
  .cardName {
    font-size: 22px;
  }

  .price {
    font-size: 16px;
  }
}

@media (min-width: variables.$media-md) {
  .cardName {
    font-size: 18px;
  }

  .price {
    font-size: 20px;
  }
}

@media (min-width: variables.$media-xl) {
  .cardName {
    font-size: 22px;
  }

  .price {
    font-size: 24px;
  }
}

@use 'src/styles/variables';

.greyBackground {
  background-color: variables.$N2;
}

.mainContainer {
  max-width: 1440px;
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
}

@media (min-width: variables.$media-sm) {
  .mainContainer {
    padding: 0 24px;
  }
}

@media (max-width: variables.$media-sm) {
  .greyBackground {
    background-color: variables.$N1;
  }
}

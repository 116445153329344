@import 'src/styles/variables';
@import 'src/styles/fonts';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: $N1;
  font-family: $font-body;
  min-width: 375px;
}

.container {
  max-width: 1440px;
  padding: 0 16px;
  width: 100%;
  margin: 0 auto;
}

.ant-modal-content {
  padding: 0 !important;
}

@media (min-width: $media-sm) {
  .container {
    padding: 0 24px;
  }
}

.display-none {
  display: none;
}

@use 'src/styles/variables';

.uploadContainer {
  :global {
    .ant-tooltip {
      display: none !important;
    }

    .ant-upload-list-item {
      border: 1px solid variables.$secondary !important;
    }

    .ant-upload-list-item-actions,
    .ant-upload-list-item-action {
      opacity: 1 !important;
    }
  }

  .button {
    width: 100%;
    height: 82px;
    border: 0;
    border-radius: 10px;
    background: none;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  div {
    height: 82px !important;
    width: 100% !important;
  }
}

@media (min-width: variables.$media-sm) {
  .uploadContainer {
    .button {
      height: 154px;
    }

    div {
      height: 154px !important;
    }

    .cameraIcon {
      width: 40px;
      height: 40px;
    }
  }
}

@use 'src/styles/variables';

.heroSectionRow {
  margin-top: 40px;
  margin-bottom: 38px;
}

.image {
  display: flex;
  width: 100%;
  max-height: 300px;
}

.heading {
  font-family: variables.$font-heading;
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 150%;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}

.paragraph {
  font-size: 18px;
  font-family: variables.$font-body;
  color: variables.$N6;
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 24px;
}

.heroContent {
  max-width: 55%;
  padding-right: 8px;
  padding-top: 200px;
}

@media (min-width: variables.$media-sm) {
  .heading {
    font-size: 32px;
    margin-bottom: 24px;
    line-height: 125%;
    text-align: left;
  }

  .paragraph {
    font-size: 22px;
    margin-bottom: 40px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 24px;
  }

  .image {
    max-height: 300px;
    margin-bottom: 12px;
  }

  .heroSectionRow {
    margin-bottom: 80px;
  }
}

@media (min-width: variables.$media-md) {
  .image {
    margin-bottom: 0;
  }
}

@media (min-width: variables.$media-xl) {
  .heading {
    font-size: 56px;
    margin-bottom: 40px;
    font-weight: 600;
    line-height: 150%;
  }

  .paragraph {
    font-size: 40px;
    margin-bottom: 40px;
    font-weight: 500;
  }

  .heroSectionRow {
    margin-bottom: 140px;
  }
  .image {
    max-height: 500px;
  }
}

@use 'src/styles/variables';

.input {
  border-radius: 8px;
  transition: all 0.3s;
  height: 48px;
}

:global {
  .ant-form-item-has-success input,
  .ant-input-status-success {
    border-color: variables.$success;
  }
}
